// a
export const APP_NAME = "Xtream Cloud";

// const originalHost = "https://dev-api.xtream.cloud/provider/"; //https://xc.productgid.com || process.env.REACT_APP_API_HOST
const originalHost = "{{REACT_APP_API_HOST}}/provider/"; //https://xc.productgid.com || process.env.REACT_APP_API_HOST
export const API_HOST_DEV_ADS = "https://dev-ads.xtream.cloud"; //process.env.REACT_API_HOST_DEV_ADS
export const API_HOST = originalHost.replace("/provider/", "");
export const APP_VERSION = "1.1.60";
// b
// c
export const CURRENCY = "USD";
// d
// e
export const VPN_DESKTOP_LINK =
  "https://chromewebstore.google.com/detail/tunnelbear-vpn/omdakjcmkglenbhjadbccaookpfjihpa";
export const VPN_DESKTOP_HELP_LINK =
  "https://www.youtube.com/watch?v=B82f2opMMk8";

export const VPN_IOS_LINK = "https://apps.apple.com/app/id1410235921";
export const VPN_IOS_HELP_LINK = "https://www.youtube.com/watch?v=qwQOLOkDfak";

export const VPN_ANDROID_LINK =
  "https://play.google.com/store/apps/details?id=com.freevpnplanet";
export const VPN_ANDROID_HELP_LINK =
  "https://www.youtube.com/watch?v=Vx-3soQp_9U";

// f
// g
// h
// i
// j
export const JOIN_COMMUNITY = "https://t.me/xtream_cloudtv";
// k
// l
// m
// n
// o
// p
export const PAYPAL_CLIENT_ID =
  process.env.PAYPAL_CLIENT_ID ||
  "AUgTSjtq7N59pymJRRuKcXj_G5WtIwmywHwt9QdkWkpBkq0GbhHRUQW9jI-k5OsxXmsNqp_kOq6RbD39";
// q
// r
// s
export const SUPPORT = "mailto:xcloudapps@gmail.com";
// t
export const TELEGRAM = "https://t.me/xtreamcloud";
// u
// v
// w
export const WEB_HOST =
  process.env.REACT_APP_WEB_HOST || "https://xtream.cloud";
// x
// y
// z
